import React, { useContext } from "react";
import * as Sentry from "@sentry/browser";

import { AuthContext } from "../../Authorization/AuthContext";

import { CreateSubmission } from "../../Submissions/CreateSubmission";
import { HELPSCOUT_BEACON_ID, useHelpscoutBeacon } from "../utils";
import { INTAKE_SOURCE, SUBMISSION_CATEGORY } from "common/constants";
import { arrayHasAtLeastOneItem } from "common/utils/tools";

const UploadElevationCertificatesPage = () => {
  const { account } = useContext(AuthContext);
  useHelpscoutBeacon(HELPSCOUT_BEACON_ID.EC);

  const submissionTypes = account?.submissionTypes.filter(
    type =>
      type.category === SUBMISSION_CATEGORY.PERMITTING &&
      type.intakeSource === INTAKE_SOURCE.EXTERNAL
  );

  if (!arrayHasAtLeastOneItem(submissionTypes)) {
    return <div />;
  }

  if (submissionTypes.length > 1) {
    Sentry.captureMessage(
      `Multiple submission types match criteria for guest EC upload: ${submissionTypes
        .map(st => st.id)
        .join(", ")}`
    );
  }

  return <CreateSubmission submissionTypeId={submissionTypes[0].id} />;
};

export default UploadElevationCertificatesPage;
