import React, { useContext } from "react";
import { useToasts } from "react-toast-notifications";
import { useLocation } from "react-router";

import GetHelpForm from "./Form";
import ThumbsUp from "../../Common/Icons/ThumbsUp";
import Icon, { ICON_COLORS, Icons } from "../../Common/Icons";
import { setTitle } from "../../../utils/title";
import { track } from "../../../utils/tracking";
import {
  useCreatePublicInformationRequestMutation,
  useGetGetHelpDataQuery,
  useGetPropertyPublicDetailsQuery,
} from "../../../generated/graphql";
import Layout from "../Layout";
import {
  accountTitle,
  HELPSCOUT_BEACON_ID,
  useHelpscoutBeacon,
} from "../utils";
import { PageSubheader } from "../PageSubheader";
import { AuthContext } from "../../Authorization/AuthContext";

import "./__styles__/index.scss";
import { entries, isEmpty, omit, pickBy, startCase } from "lodash";

const SuccessMessage = () => {
  return (
    <div styleName="line-block">
      <div styleName="success-message">
        <h1 tabIndex={0}>Help Request</h1>
        <div styleName="icon-wrapper">
          <ThumbsUp />
        </div>
        <p tabIndex={0}>
          Thank you! We have received your request and will follow up shortly to
          find a time to talk.
        </p>
      </div>
    </div>
  );
};

const GetHelpPage = () => {
  setTitle(`Get help | ${accountTitle()}`);
  const account = useContext(AuthContext).account!;

  const { addToast } = useToasts();
  const location = useLocation();
  const [success, setSuccess] = React.useState(false);
  const queryParams = new URLSearchParams(location.search);
  const propertyId = queryParams.get("propertyId") || undefined;

  const {
    data: propertyData,
    loading: propertyLoading,
    error: propertyError,
  } = useGetPropertyPublicDetailsQuery({
    skip: !propertyId,
    variables: { propertyId: propertyId! },
  });

  const {
    data: getHelpData,
    loading: getHelpLoading,
    error: contactError,
  } = useGetGetHelpDataQuery();

  useHelpscoutBeacon(HELPSCOUT_BEACON_ID.GET_HELP);

  const [create] = useCreatePublicInformationRequestMutation({
    onCompleted: () => {
      track("Submitted Get Help Form");
      addToast("Form submitted successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      setSuccess(true);
    },
    onError: () => {
      addToast("Oops! Encountered an error. Please try again", {
        appearance: "error",
        autoDismiss: true,
      });
    },
  });

  if (propertyLoading || getHelpLoading) {
    setTitle(`Get help`);
    return (
      <div styleName="loading">
        <Icon icon={Icons.LOADING} color={ICON_COLORS.LIGHT_GREY} />
      </div>
    );
  }

  if (propertyError || contactError) {
    return null;
  }

  const property = propertyData?.property;
  const informationRequestInput: {
    propertyId?: string;
    address?: Maybe<string>;
    longitude?: Maybe<number>;
    latitude?: Maybe<number>;
    submittedAt: Date;
  } = property
    ? {
        propertyId: propertyId,
        address: property.fullAddress,
        longitude: property.longitude,
        latitude: property.latitude,
        submittedAt: new Date(),
      }
    : { submittedAt: new Date() };

  const contactInformation = omit(getHelpData?.account?.contactInformation, [
    "id",
    "__typename",
  ]);

  const nonEmptyContactInformation = pickBy(
    contactInformation || {},
    info => !isEmpty(info)
  );
  const showContactInformation = !isEmpty(nonEmptyContactInformation);

  const subjectOptions =
    getHelpData!.account!.publicPortal.accountInformationRequestHelpOptions.map(
      option => {
        return {
          value: option.id,
          label: option.text,
          reasonCodes: option.reasonCodes,
        };
      }
    );

  return (
    <Layout propertyId={property?.id}>
      {success ? (
        <SuccessMessage />
      ) : (
        <>
          <PageSubheader title="Get help">
            <p>
              Answer the questions below. Once the form is submitted, a
              representative from {account.name} will follow up shortly.
            </p>
            {showContactInformation && (
              <p>
                If you'd like to reach out directly, please contact:
                <br />
                {entries(nonEmptyContactInformation).map(([field, value]) => {
                  return (
                    <React.Fragment key={field}>
                      <strong>{startCase(field)}:</strong> {value} <br />{" "}
                    </React.Fragment>
                  );
                })}
              </p>
            )}
          </PageSubheader>
          <div styleName="container">
            <GetHelpForm
              data={informationRequestInput}
              subjectOptions={subjectOptions}
              onSave={async data => {
                await create({ variables: { data } });
              }}
            />
          </div>
        </>
      )}
    </Layout>
  );
};

export default GetHelpPage;
